<div class="dialog-wrap {{inputMode}}" #dialogWrap>
    <nb-card *ngFor="let data of this.data; let i=index" style="max-height: initial; margin: 0 auto; width: 22cm;" class="small-header">
        <nb-card-header>
            <ngx-card-header [option]="{controlOption: {index: i}}" [size]="size" [icon]="favicon" [title]="data['Title'] || ('Tổng hợp công nợ phải thu' | translate:{definition: '', action: cms.translate.instant('Common.print')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
        </nb-card-header>
        <nb-card-body class="print-body" #printContent>
            <div id="print-area">
                <ngx-print-header></ngx-print-header>
                <div>
                    <div class="print-title">{{ this.title | uppercase }}</div>
                    <div class="under-title">Từ {{data.FromDate | date: 'short'}} đến {{ data.ToDate | date:'short' }}</div>
                    <div class="under-title">Ngày giờ báo cáo: {{ data.ReportDate | date: 'short'}}</div>
                </div>
                <div>
                    <div>
                        <table style="width: 100%;" class="print-voucher-detail-table ">
                            <!-- <colgroup>
                                <col span="1" style="width: 1cm;">
                                <col span="1" style="width: 2cm;">
                                <col span="1" style="width: 4cm;">
                                <col span="1" style="width: 3cm;">
                                <col span="1" style="width: 2cm; text-align: right;">
                            </colgroup> -->
                            <thead class="print-voucher-detail-header">
                                <td>#</td>
                                <td class="">{{'Common.code' | translate | headtitlecase}}</td>
                                <td class="">{{'Common.customer' | translate | headtitlecase}}</td>
                                <td class="">{{'Nhóm' | translate | headtitlecase}}</td>
                                <td class="">{{'Common.phone' | translate | headtitlecase}}</td>
                                <td class="" style="white-space: nowrap">{{'Common.address' | translate | headtitlecase}}</td>
                                <td class="">{{'Common.note' | translate | headtitlecase}}</td>
                                <td class="text-align-right">{{'Đầu kỳ' | translate | headtitlecase}}</td>
                                <td class="text-align-right">{{'Phát sinh' | translate | headtitlecase}}</td>
                                <td class="text-align-right">{{'Cuối kỳ' | translate | headtitlecase}}</td>
                            </thead>
                            <tr class="print-voucher-detail-line" *ngFor="let detail of data.Details; let i = index">
                                <td>{{i + 1}}</td>
                                <td>{{detail.Object}}</td>
                                <td>{{detail.ObjectName}}</td>
                                <td><ng-container *ngIf="detail.ObjectContactGroups">{{detail.ObjectContactGroups | objectstext}}</ng-container></td>
                                <td>{{detail.ObjectPhone}}</td>
                                <td>{{detail.ObjectAddress}}</td>
                                <td>{{detail.ObjectNote}}</td>
                                <td class="text-align-right" [innerHtml]="renderCurrency(detail.HeadAmount)"></td>
                                <td class="text-align-right" [innerHtml]="renderCurrency(detail.GenerateDebit - detail.GenerateCredit)"></td>
                                <td class="text-align-right" style="font-weight: bold;" [innerHtml]="renderCurrency(detail.TailAmount)"></td>
                            </tr>
                            <tr style="font-weight: bold;">
                                <td colspan="8">Ghi chú: {{note}}</td>
                                <td class="text-align-right">{{'Voucher.total' | translate | headtitlecase}}</td>
                                <td class="text-align-right" [innerHtml]="renderCurrency(data['Total'])"></td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div style="font-style: italic;">
                    <b>* Chú thích</b>: <br>
                    - Cột <b>«Đầu kỳ»</b> Công nợ trước {{data.FromDate | date: 'short'}}<br>
                    - Cột <b>«Phát sinh»</b>: Công nợ từ {{data.FromDate | date: 'short'}} -  {{data.ToDate | date: 'short'}}<br>
                    - Cột <b>«Cuối kỳ»</b>: Công nợ sau {{data.ToDate | date: 'short'}}<br>
                    - Số tiền trong ngoặc (...) thể hiện số tiền thanh toán nhiều hơn số nợ
                </div>
                <div style="display: flex; flex-wrap: wrap">
                    <div style="text-align: center; flex: 0 0 50%;">
                        <br> {{'Người lập báo cáo' | translate | headtitlecase}}
                    </div>
                    <div style="text-align: center; flex: 0 0 50%;">
                        
                    </div>
                </div>
            </div>
        </nb-card-body>
        <nb-card-footer>

            <div class="buttons-row">
                <button nbButton hero status="danger" (click)="close();">
                    <nb-icon pack="eva" icon="close-outline"></nb-icon>ESC
                </button>
                <button nbButton hero status="primary" (click)="print(i);">
                    <nb-icon pack="eva" icon="printer-outline"></nb-icon>{{'Common.print' | translate | headtitlecase}}
                </button>
                <!-- <button nbButton hero status="info" (click)="prepareCopy(data);" style="float: left">
                    <nb-icon pack="eva" icon="copy"></nb-icon>{{'Common.copy' | translate | headtitlecase}}
                </button> -->
                <!-- <button nbButton hero status="danger" (click)="approvedConfirm(data);">{{(processMapList[i]?.nextStateLabel || '') | translate | headtitlecase}}<nb-icon pack="eva" icon="skip-forward"></nb-icon></button> -->
                
                <!--State process buttons-->
                <button *ngFor="let nextState of processMapList[i]?.nextStates" nbButton hero status="{{nextState.status}}" (click)="stateActionConfirm(data, nextState);">{{nextState.confirmLabel | translate | headtitlecase}}<nb-icon pack="{{nextState.iconPack || 'eva'}}" icon="{{nextState.icon || 'skip-forward'}}"></nb-icon></button>
                <!--End State process buttons-->

                <!-- <button *ngIf="!sourceOfDialog || sourceOfDialog === 'form'" nbButton hero status="success" (click)="saveAndClose(data);">
                    <nb-icon pack="eva" icon="save"></nb-icon>{{'Common.saveAndClose' | translate | headtitlecase}}
                </button> -->
            </div>

        </nb-card-footer>
    </nb-card>
</div>