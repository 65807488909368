<form [formGroup]="form" (keydown.shift.enter)="onControlEnter($event)" (keydown.enter)="onControlEnter($event)" class="dialog-wrap popup">
    <div formArrayName="array">
        <div *ngFor="let formItem of array.controls; let i = index">
            <nb-card class="small-header popup">
                <nb-card-header>
                    <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Phiếu cập nhật giá' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
                </nb-card-header>
                <nb-card-body>
                    <div [formGroup]="formItem">

                        <label class="label label-with-border-below">{{'Common.deliveryAndPayment' | translate | headtitlecase}}</label>
                        <div class="row dashed-radius-border">

                            <div class="col-sm-12">
                                <ngx-form-group [formGroup]="formItem" name="Title" label="Common.title" [array]="array" [index]="i" [allowCopy]="false" [required]="true" [touchedValidate]="false">
                                    <input type="text" nbInput fullWidth formControlName="Title" placeholder="{{'Common.title' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-12">
                                <ngx-form-group [formGroup]="formItem" name="Note" label="Common.note" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <textarea class="scrollable-container" nbInput fullWidth formControlName="Note" placeholder="{{'Common.note' | translate | headtitlecase}}" rows="4"></textarea>
                                </ngx-form-group>
                            </div>
                        </div>

                        <!-- Detail form -->
                        <label class="label label-with-border-below">{{'Voucher.productOrservice' | translate | headtitlecase}}</label>
                        <div class="row dashed-radius-border">
                            <div class="col-sm-12">

                                <div formArrayName="Details" class="form-details" [sortablejs]="getDetails(formItem)" [sortablejsOptions]="{ handle: '.sorting-handle' }">
                                    <div class="form-detail-item" *ngFor="let detail of getDetails(formItem).controls; let ic=index" [formGroup]="detail">
                                        <hr>
                                        <div class="row">
                                            <div class="col-lg-2 col-md-2 col-sm-12 col-is-12">
                                                <div class="row">
                                                    <div class="col-lg-3 col-md-3 col-sm-3 col-is-3">
                                                        <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="No" label="STT" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="false" [required]="false">
                                                            <input nbInput class="sorting-handle" title="Kéo thả để di chuyển" fullWidth formControlName="No" value="{{ic+1}}" readonly style="text-align: center; cursor: grab; width: 100%; height: 2.5rem; padding: 0;">
                                                            <!-- <input type="hidden" formControlName="Id"> -->
                                                        </ngx-form-group>
                                                        <div class="form-group">
                                                            <button nbButton status="danger" hero size="medium" (click)="removeDetailGroup(formItem, detail, ic)" style="width: 100%; margin-bottom: 3px; padding: 0">
                                                                <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
                                                            </button>
                                                        </div>
                                                        <div class="form-group">

                                                        </div>
                                                    </div>

                                                    <div class="col-lg-9 col-md-9 col-sm-9 col-is-9">
                                                        <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="Image" label="Common.image" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="false" [required]="false">
                                                            <ngx-files-input formControlName="Image" [config]="{style: {height: '6.9rem', padding: '0'}, thumbnailStype: {height: '5rem', width: '100%'}}"></ngx-files-input>
                                                        </ngx-form-group>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-10 col-md-10 col-sm-12 col-is-12">
                                                <div class="row">
                                                    <div class="col-lg-4 col-md-4 col-sm-12 col-is-12">
                                                        <div class="row">
                                                            <div class="col-lg-4 col-is-12">
                                                                <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="PriceType" label="Common.type" [array]="formItem._details" [index]="ic" [allowCopy]="true" [required]="false">
                                                                    <ngx-select2 [data]="select2DataForType" formControlName="PriceType" [select2Option]="select2OptionForType"></ngx-select2>
                                                                </ngx-form-group>
                                                            </div>
                                                            <div class="col-lg-8 col-md-8 col-sm-9 col-is-12">
                                                                <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="Product" label="Common.productOrService" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="false" [required]="trie" [touchedValidate]="false" [customIcons]="getCustomIcons(i + ic)" [option]="{parentForm: formItem}">
                                                                    <ngx-select2 formControlName="Product" [select2Option]="select2OptionForProduct" (selectChange)="onSelectProduct(detail, $event, formItem, detail)"></ngx-select2>
                                                                </ngx-form-group>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-8 col-md-8 col-sm-12 col-is-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 col-md-2 col-sm-2 col-is-12">
                                                                <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="Unit" label="Voucher.unit" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="false" [required]="true" [touchedValidate]="false" [customIcons]="unitCustomIcons">
                                                                    <ngx-select2 formControlName="Unit" [data]="detail.get('Unit')['UnitList'] || unitList" [select2Option]="select2OptionForUnit" (selectChange)="onSelectUnit(detail, $event, formItem)"></ngx-select2>
                                                                </ngx-form-group>
                                                            </div>
                                                            <div class="col-lg-2 col-md-4 col-sm-3 col-is-12">
                                                                <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="PurchasePrice" label="Giá nhập" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="false" [required]="false" [touchedValidate]="false" [align]="'right'">
                                                                    <input type="text" nbInput fullWidth formControlName="PurchasePrice" placeholder="{{'Giá nhập' | translate | headtitlecase}}" class="align-right" currencyMask [options]="priceCurencyFormat" (focus)="currencyMaskFocus($event, priceCurencyFormat)" (keydown)="currencyMastKeydown($event, priceCurencyFormat)" (paste)="onPasteNumber($event, priceCurencyFormat)">
                                                                </ngx-form-group>
                                                            </div>
                                                            <div class="col-lg-2 col-md-4 col-sm-3 col-is-12">
                                                                <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="ProfitMargin" label="% lợi nhuận" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="false" [required]="false" [touchedValidate]="false" [align]="'right'">
                                                                    <input type="text" nbInput fullWidth formControlName="ProfitMargin" placeholder="{{'% lợi nhuận' | translate | headtitlecase}}" class="align-right" [inputMask]="towDigitsInputMask" (paste)="onPasteNumber($event, priceCurencyFormat)">
                                                                </ngx-form-group>
                                                            </div>
                                                            <div class="col-lg-2 col-md-4 col-sm-3 col-is-12">
                                                                <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="OldPrice" label="Giá hiện tại" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="false" [required]="false" [touchedValidate]="false" [align]="'right'">
                                                                    <input type="text" nbInput fullWidth formControlName="OldPrice" placeholder="{{'Giá hiện tại' | translate | headtitlecase}}" class="align-right" currencyMask [options]="priceCurencyFormat" (focus)="currencyMaskFocus($event, priceCurencyFormat)" (keydown)="currencyMastKeydown($event, priceCurencyFormat)" (paste)="onPasteNumber($event, priceCurencyFormat)">
                                                                </ngx-form-group>
                                                            </div>
                                                            <div class="col-lg-3 col-md-4 col-sm-3 col-is-12">
                                                                <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="Price" label="Giá EU" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="false" [required]="false" [touchedValidate]="false" [align]="'right'">
                                                                    <input type="text" nbInput fullWidth formControlName="Price" placeholder="{{'Giá EU' | translate | headtitlecase}}" class="align-right" currencyMask [options]="priceCurencyFormat" (focus)="currencyMaskFocus($event, priceCurencyFormat)" (keydown)="currencyMastKeydown($event, priceCurencyFormat)" (paste)="onPasteNumber($event, priceCurencyFormat)">
                                                                </ngx-form-group>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-is-12">
                                                        <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="Description" label="Common.description" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="true" [required]="true" [touchedValidate]="false">
                                                            <textarea class="scrollable-container" nbInput fullWidth formControlName="Description" placeholder="{{'Common.description' | translate | headtitlecase}}" rows="1"></textarea>
                                                        </ngx-form-group>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-lg-2 col-md-4 col-sm-12 col-is-12">
                                            <button nbButton status="success" (click)="addDetailFormGroup(formItem)" size="medium" hero [disabled]="isProcessing">
                                                <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>{{'Sales.addProduct' | translate | headtitlecase}}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12" style="text-align: right;">

                            </div>
                        </div>
                        <!-- End Detail form -->

                    </div>
                </nb-card-body>
                <nb-card-footer>
                    <div class="buttons-row" style="text-align: right;">
                        <button nbButton hero status="primary" (click)="preview([formItem.value])" [disabled]="!form.valid || isProcessing">
                            <nb-icon on b-icon pack="eva" icon="printer"></nb-icon>{{'Common.preview' | translate | headtitlecase}}
                        </button>
                        <button nbButton status="danger" [disabled]="!form.valid || isProcessing" style="float: right;" hero (click)="saveAndClose()">{{'Common.saveAndClose' | translate | headtitlecase}}</button>
                        <button nbButton status="success" [disabled]="!form.valid || isProcessing" style="float: right;" hero (click)="save()">{{'Common.save' | translate | headtitlecase}}</button>
                    </div>
                </nb-card-footer>
            </nb-card>
        </div>
    </div>
</form>